
import React, { useEffect } from 'react'
import { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

import scss from './Header.module.scss'
// import scssLayout from 'views/Layout.module.scss'
// import BaseToggle from './BaseToggle';
import BaseButton from './BaseButton';
import { snackInfo } from './SnackBar';
import paths from 'utils/apiPath';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/axios';
import cookie from 'utils/cookie';
//---------------------------------------------------------------------------
export default function Header(props) {
  const {isHomePage = false, target = "", setInfo, ...other} = props
  
  const token = cookie.getCookie(cookie.keys.auth.token)
  
  const navigate = useNavigate()
  const [state, setState] = useState(axiosState.init())
  const apiLogOut = () => {
    const config = apiConfig.auth.logout(token)
    // console.log(config);
    
    axios(config).then(result => {
      result.data.result_code = result.status
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        
        cookie.removeCookieUser()
        setInfo(snackInfo.openSuccess("登出成功"))
        navigate(paths.site.home)
      }
    }).catch(err => {
      // console.error("Logout error:", err)
      let msg = axiosCatch.getMsg(err)
      let code = axiosCatch.getResultCode(err)
      // console.log('msg', msg);
      // console.log('code', code);
      cookie.removeCookieUser()
      if( code === 911 || code === 912 ) { //"Incorrect Authentication" for token not exist
        setInfo(snackInfo.openSuccess("登出成功"))
        navigate(paths.site.home)
      } else {
        setInfo(snackInfo.openError(msg))
      }
    })
  }
  
  //------------------------------------------------------------------------------
  if (isHomePage) return (
    <div className={scss.layout} {...other}>
      <div className={scss.frame} style={{ "margin": "0 10px" }}>
        <span className={scss.title}>
          {props.children}
        </span>
        {token && <BaseButton onClick={apiLogOut}>登出</BaseButton>}
      </div>
    </div>
  )
  
  //------------------------------------------------------------------------------
  return (
    <div className={scss.layout} {...other}>
      <div className={scss.frame}>
        <IconButton aria-label="back" size="large" onClick={() => navigate(-1)}>
          <ArrowBackIcon className={scss.icon} />
        </IconButton>

        <span className={scss.title}>{props.children}</span>

        <Tooltip title={props.target}>
          <IconButton>
            <InfoIcon className={scss.icon}/>
          </IconButton>
        </Tooltip>
        <span />
      </div>
    </div>
)}
