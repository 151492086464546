import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell , { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import paths from 'utils/apiPath'
import MuiSelect from './MuiSelect';
//------------------------------------------------------------------------------
// <BasicTable isStatic={true} category={"精舍"} rowData={staticData}/>

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontFamily:'LXGWWenKaiTC-Regular',
  fontSize: "1.5rem",
  border:0,
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    "color": 'var(--color-green)',
    "fontSize": "1.8rem",
    "fontWeight":"bold",
    "borderBottom": "1px solid var(--color-gray-600)"
  },
  [`&.${tableCellClasses.body}`]: {
    "color": 'var(--color-second-brown)',
    "fontWeight":"bold",
  },
}));

//------------------------------------------------------------------------------
export default function BasicTable({isStatic = false,category, rowData=[], count,handleChange,unitType="部"}) {
  const navigate = useNavigate()
  const newData = rowData.map((row, index) => {
    const routes = [
      paths.site.sutra,
      paths.site.verse,
      paths.site.talk,
      paths.site.meditation,
      paths.site.vows,
    ];
    return {
      event_id: row.event_id,
      today_count: row.today_count,
      total_count: row.total_count,
      route: routes[index] 
    };
  });


  if (isStatic) return (
    <TableContainer
      style={{
        "border": "1px solid var(--color-gray-600)",
        "borderRadius": '20px',
        "marginBottom": "5%"
      }}
    >
      <Table
        style={{
          "backgroundColor": "rgba(243, 232, 220, 0.6)",
          "overflow": "visible",
        }}
      >
        <TableHead>
          <TableRow >
            <StyledTableCell>{category}</StyledTableCell>
            <StyledTableCell>總計</StyledTableCell>
            <StyledTableCell>當月</StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {newData.map((row) => (
            <TableRow
              key={row.event_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StyledTableCell component="th" scope="row" style={{ color: 'var(--color-green)' }}>{row.event_id} </StyledTableCell>
              <StyledTableCell>{row.today_count}</StyledTableCell>
              <StyledTableCell>{row.total_count}</StyledTableCell>
              <StyledTableCell>
                <IconButton onClick={() => navigate(row.route)}>
                  < ChevronRightIcon style={{color:'var(--color-green)'}}/>
                </IconButton>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <TableContainer
      style={{
        "border": "1px solid var(--color-gray-600)",
        "borderRadius": '20px',
        "marginBottom": "5%"
      }}
    >
      <Table
        style={{
          "backgroundColor": "rgba(243, 232, 220, 0.6)",
          "overflow": "visible",
        }}
      >
        <TableBody>
          {newData.map((row) => (
            <TableRow
              key={row.event_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StyledTableCell component="th" scope="row" style={{ color: 'var(--color-green)' }}>{row.event_id} </StyledTableCell>
              <StyledTableCell>{row.today_count || row.total_count}</StyledTableCell>
              <StyledTableCell style={{ color: 'var(--color-green)' }}>{unitType}</StyledTableCell>
            </TableRow>
          ))}
          <tr>
            <StyledTableCell style={{ color: 'var(--color-green)' }}>新增</StyledTableCell>
            <StyledTableCell>
              <MuiSelect count={count} handleChange={handleChange}/>
            </StyledTableCell>
            <StyledTableCell style={{ color: 'var(--color-green)' }}>{unitType}</StyledTableCell>
          </tr>

        </TableBody>
      </Table>
    </TableContainer>
  );
}