
// eslint-disable-next-line no-extend-native
if (!String.prototype.removeAll) {
  String.prototype.removeAll = function (tag) {
    return this.replaceAll(tag, '');
  }
}

// eslint-disable-next-line no-extend-native
if (!String.prototype.to1stUpperCase) {
  String.prototype.to1stUpperCase = function () {
    let str = this
    if (!str)
      return ''; // 處理空字串或 null
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}