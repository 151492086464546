import { Navigate, useLocation, useRoutes } from "react-router-dom";

import paths from "utils/apiPath";
import cookie from "utils/cookie";
import { getFullPath } from "utils/general";

import Home from "views/Home";
import Login from "views/Login";
import Meditation from "views/Meditation";
import SignUp from "views/SignUp";
import Sutra from "views/Sutra";
import Talk from "views/Talk";
import Verse from "views/Verse";
import Vows from "views/Vows";

//---------------------------------------------------------------------------
function setRoute(path, element) {
  return {
    path: path,
    element: element,
  }
}

function setProtectedRoute(path, component) {
  return {
    path: path,
    element: (
      <ProtectedRoute redirectPath={() => this.path}>
        {component}
      </ProtectedRoute>
    ),
  }
}

function ProtectedRoute({ children, redirectPath }) {
  const token = cookie.getCookie(cookie.keys.auth.token);

  let location = useLocation();
  let fullPath = getFullPath(location)
  // console.log('fullPath', fullPath)
  return token !== "" && token !== undefined
  ? (children)
  : (<Navigate to={`${paths.auth.login}?redirect=${encodeURIComponent(fullPath)}`}/>)
}

function LoggedInRoute({ children }) {
  const token = cookie.getCookie(cookie.keys.auth.token);
  // console.log('token', token);
  return token !== "" && token !== undefined
  ? (<Navigate to={paths.site.home} />)
  : (children);
}

//---------------------------------------------------------------------------
const Router = ({ setInfo }) => {
  // console.log(endpoint.auth.login);
  // console.log(endpoint.spp.getSppData);

  let element = useRoutes([
    // site
    setProtectedRoute(paths.site.home, <Home setInfo={setInfo} />),
    setProtectedRoute( "*", <Home setInfo={setInfo} />),
    
    //---------------------------------------------------------------------------
    // auth
    {
      path: paths.auth.login,
      element: (
        <LoggedInRoute>
          <Login setInfo={setInfo} />
        </LoggedInRoute>
      ),
    },
    setRoute( paths.auth.signup, <SignUp setInfo={setInfo} />),
    //------------------------------------------------------------------------------
    // p07
    // setProtectedRoute( paths.site.xxx, <XXX setInfo={setInfo} />),
    
    setProtectedRoute( paths.site.meditation, <Meditation setInfo={setInfo} />),
    setProtectedRoute( paths.site.sutra, <Sutra setInfo={setInfo} />),
    setProtectedRoute( paths.site.talk, <Talk setInfo={setInfo} />),
    setProtectedRoute( paths.site.verse, <Verse setInfo={setInfo} />),
    setProtectedRoute( paths.site.vows, <Vows setInfo={setInfo} />),

  ]);

  return element;
}

export default Router;
