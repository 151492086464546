import * as React from 'react';

import styles from './BaseButton.module.scss';

//------------------------------------------------------------------------------
export default function BaseButton(props) {
  const {color, ...other} = props
  
  let className = styles.Button
  if( color === 'green' )
    className = styles.Button_green
  
  //------------------------------------------------------------------------------
  return (
    <button type="button" className={className} {...other}>
      {props.children}
    </button>
)}
