import React, { useEffect } from 'react'
import { useState } from "react";

import Timer from 'components/Timer';
import Header from 'components/Header';
import BaseButton from 'components/BaseButton';
import BasicTable from 'components/BasicTable';

import scssLayout from './Layout.module.scss'
import cookie from 'utils/cookie';

//---------------------------------------------------------------------------
const fakeData = {
  "item(s)": [
    {
      "event_id": 1,
      "today_count": 32,
    },
    {
      "event_id": 2,
      "total_count": 0
    }  ],
}
fakeData['item(s)'].map((row) => {
  switch (row.event_id) {
    case 1:
      row.event_id = "個人已完成"
      break;
    case 2:
      row.event_id = "今天已完成"
      break;
    default:
  }
});

//------------------------------------------------------------------------------
export default function Meditation({ setInfo }) { //Meditation=med. 靜坐
  const userName = cookie.getUserName()
  const userClass = cookie.getUserClassHead()
  
  //------------------------------------------------------------------------------
  let staticData = fakeData['item(s)'];
  const defaultCount = 1
  const [count, setCount] = useState(defaultCount)
  const handleChange = (event) => {
    setCount(event.target.value);
  };

  const [isStart, setIsStart] = useState(false)
  function onExpireTimer() {
    // console.warn('onExpire called')
  }

  //------------------------------------------------------------------------------
  return (
    <div className={scssLayout.layout}>
      <Header target={"基礎：108支香，進階：200支香"}>靜坐</Header>
      <div className={scssLayout.layout_content}>

        <span className="heading1">{userName}-{userClass}</span>
        
        <div className={scssLayout.layout_timer}>
          <div className={scssLayout.box}>
            <Timer isStart={isStart} onExpire={onExpireTimer} />
          </div>
          <BaseButton >開始</BaseButton>
        </div>

        <BasicTable rowData={staticData} count={count} handleChange={handleChange} unitType='支香' />
        <BaseButton color={'green'}>新增</BaseButton>
      </div>
    </div>);
};
