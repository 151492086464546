import React, { useEffect } from 'react'
import { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import Header from 'components/Header';
import BasicTable from 'components/BasicTable';
import BaseButton from 'components/BaseButton';

import scssLayout from './Layout.module.scss'
import cookie from 'utils/cookie';
//---------------------------------------------------------------------------
const fakeData = {
  "item(s)": [
    {
      "event_id": 1,
      "today_count": 32,
    },
    {
      "event_id": 2,
      "total_count": 0
    }  ],
}
fakeData['item(s)'].map((row) => {
  switch (row.event_id) {
    case 1:
      row.event_id = "個人已完成"
      break;
    case 2:
      row.event_id = "今天已完成"
      break;
    default:
  }
});

const greatVows =
`眾生無邊誓願度，\n
煩惱無盡誓願斷，\n
法門無量誓願學，\n
佛道無上誓願成。\n`
const greatVowsTitle = "『四弘誓願』"
//---------------------------------------------------------------------------
export default function Vows({ setInfo }) { //Vows=發願
  const userName = cookie.getUserName()
  const userClass = cookie.getUserClassHead()
  //------------------------------------------------------------------------------
  
  let staticData = fakeData['item(s)'];
    const defaultCount = 1 
    const [count, setCount] = useState(defaultCount)
  const handleChange = (event) => {
    setCount(event.target.value);
  };
  
  //------------------------------------------------------------------------------
  return (
    <div className={scssLayout.layout}>
      <Header target={"基礎：150次，進階：300次以上"}>發願</Header>
      <div className={scssLayout.layout_content}>
        <span className="heading1">{userName}-{userClass}</span>
        <div>
          <p className={scssLayout.layout_text}>{greatVowsTitle}</p>
          <p className={scssLayout.layout_text}>{greatVows}</p>
        </div>
        <BasicTable rowData={staticData} count={count} handleChange={handleChange} unitType='次' />
        <BaseButton color={'green'}>新增</BaseButton>
      </div>
    </div>);
};
