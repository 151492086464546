import cookie from "utils/cookie";
import { tokenSystem } from "./apiConfig";

// property for Backend API & DB
function json() {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    // "Access-Control-Allow-Methods": "GET, POST"
    "X-Auth-Token": cookie.getCookie(cookie.keys.auth.token),
  };
}

function token(token) {
  let tokenUser =
    token !== undefined ? token : cookie.getCookie(cookie.keys.auth.token);
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Auth-Token": tokenUser,
  };
}

const headers = {
  jsonDefault: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Auth-Token": tokenSystem,
  },

  json,
  token,
};

//---------------------------------------------------------------------------
const common = {
  output: {
    items: "item(s)",
    table_name: "table_name",
  },
  response: {
    msg: "message",
    code: "result_code",
    reason: "reason",
  },
  total: "total",
};

//---------------------------------------------------------------------------
// authentication
const auth = {
  //1 register acc001
  signup: class {
    static input(user_name, dharma_name, class_id, birthday, is_student, introducer) {
      const result = {
        user_name,
        "class_id": Number(class_id),
        "birthday": String(birthday),
        "role_id": 4, //4=Member
        "vihara_id": 1, //1=普民精舍
        "is_student": Boolean(is_student),
      };
      if (dharma_name) result.dharma_name = dharma_name;
      if (introducer) result.introducer = introducer;
      return result
    }
  },
  
  //3 login acc002
  login: class {
    static input(user_name, class_id, birthday) {
      return {
        user_name,
        "class_id": Number(class_id),
        "birthday": String(birthday),
      };
    }
  },

  response: {
    token: "X-Auth-Token",
    role: "role_id",
    id: "user_id",
    class_name: 'class_name',
    class_time: 'class_time',
    name: 'user_name',
    dharma_name: 'dharma_name',
  },
};

//---------------------------------------------------------------------------
// site
const site = {
  //20 clss_list vh004
  class_list: class {
    static items = {
      class_id: "class_id",
			class_name: "class_name",
			class_time: "class_time",
    };
  },
};

//------------------------------------------------------------------------------
const p07 = {
  common,
  
  all_numbers: class {
    static input(event_id, month) {
      const result = {}
      if (event_id !== undefined) result.event_id = Number(event_id);
      if (month !== undefined) result.month = month;
      return result
    }
  },
  
  account_numbers: class {
    static input(event_id, month) {
      const result = {}
      if (event_id !== undefined) result.event_id = Number(event_id);
      if (month !== undefined) result.month = month;
      return result
    }
  }
};

/* "item(s)": [
  {
    "event_id": 1,
    "event_name": "恭誦《金剛經》"
  },
  {
    "event_id": 2,
    "event_name": "誦讀法語"
  },
  {
    "event_id": 3,
    "event_name": "發願"
  },
  {
    "event_id": 4,
    "event_name": "靜坐"
  },
  {
    "event_id": 5,
    "event_name": "研讀開示"
  }
], */

//---------------------------------------------------------------------------
const jsons = {
  headers,
  /* body */
  auth, //user
  site,
  p07,

  common,
};
export { headers, p07 };
export default jsons;
