import { useRef, useEffect } from 'react';
import { Field } from '@base-ui-components/react/field';

import styles from './BaseField.module.scss';
import { async } from 'validate.js';
/* e.g.
<BaseField label='Name' required={true} placeholder='Required'
  error='Please enter your name' description='Visible on your profile'
  value={value} onChange={onChange} />
 */
//------------------------------------------------------------------------------
export default function BaseField(props) {
  const {label = '', placeholder = '', description = '',
    error = '', required = false, 
    errorTooShort = '', //with 'minlength' e.g. minlength={4}
    errorPattern = '', //with 'pattern' e.g. pattern="[0-9]{4}"
    setIsOK = null,
    ...other} = props
    
  const ref = useRef(null)
  useEffect(() => {
    async function checkValidity() {
      if (!ref.current) return;
      
      /* ValidityState {
        badInput: false
        customError: false
        patternMismatch: true
        rangeOverflow: false
        rangeUnderflow: false
        stepMismatch: false
        tooLong: false
        tooShort: true
        typeMismatch: false
        valid: false
        valueMissing: false
      } */
      const validity = ref.current.validity;
      const disabled = ref.current.disabled;
      // console.log(placeholder, 'validity', validity);
      // console.log(placeholder, 'disabled', disabled);
      let ok = true;
      if (disabled) ok = false;
      if (error && validity.valueMissing && required) ok = false;
      if (errorTooShort && validity.tooShort) ok = false;
      if (errorPattern && validity.patternMismatch) ok = false;
      
      // console.log(placeholder, 'ok', ok);
      if (setIsOK) setIsOK(ok)
    };

    // 監聽 input 事件，當使用者輸入時更新狀態
    ref.current?.addEventListener("input", checkValidity);
    checkValidity(); // 初次檢查

    return () => ref.current?.removeEventListener("input", checkValidity);
  }, [])
  
  //------------------------------------------------------------------------------
  return (
    <Field.Root className={styles.Field}>
      {label && <Field.Label className={styles.Label}>{label}</Field.Label>}
      <Field.Control ref={ref} required={required} placeholder={placeholder} className={styles.Input} {...other} />

      {error && required && <Field.Error className={styles.Error} match="valueMissing">
        {error}
      </Field.Error>}
      {errorTooShort && <Field.Error className={styles.Error} match="tooShort">
        {errorTooShort}
      </Field.Error>}
      {errorPattern && <Field.Error className={styles.Error} match="patternMismatch">
        {errorPattern}
      </Field.Error>}

      {description && <Field.Description className={styles.Description}>
        {description}
      </Field.Description>}
    </Field.Root>
)}
