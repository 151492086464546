// Port Number 8080 from BE app
// export const restApiUrl = "http://localhost:8080"
export const restRelApiUrl = "http://54.151.177.33:443";
export const restDevApiUrl = "http://54.151.177.33:5050";

// role System=1, Root=2, Admin=3, Member=4
export const tokenSystem = "eb1d3322-018a-11f0-9e43-06fed1363c29";
export const tokenRoot = "eef2e367-018a-11f0-9e43-06fed1363c29";
export const tokenAdmin = "f4ebe798-018a-11f0-9e43-06fed1363c29";

export const tokens = {
  system: tokenSystem,
  root: tokenAdmin,
  admin: tokenAdmin,
}