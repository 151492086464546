import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { counter } from 'utils/data';
//------------------------------------------------------------------------------

export default function MuiSelect({count, handleChange }) {

  return (
    <Box>
        <Select
          value={count}
          onChange={handleChange}
        >
          {counter.map((num) => (
            <MenuItem key={num} value={num}>
              {num}
            </MenuItem>
          ))}
        </Select>
    </Box>
  );
}