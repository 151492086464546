import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";

import BG from './assets/img/BG.jpg';
import './App.css';
import 'assets/css/common.scss';
import Router from "./routes";
import ScrollToTop from "components/ScrollToTop";
import SnackBar, { snackInfo } from "components/SnackBar";

//------------------------------------------------------------------------------
function App() {
  const [info, setInfo] = useState(snackInfo.init())
  
  //------------------------------------------------------------------------------
  return (
    <BrowserRouter>
      <div className="App">
        <img src={BG} className='BG' alt="background" />
        
        <div className='layout' >
          <ScrollToTop />
          <Router setInfo={setInfo} />
        </div>
          
        <SnackBar info={info} onClose={() => { setInfo(snackInfo.close(info.type, info.msg)) }} />
      </div>
    </BrowserRouter>
  );
}

export default App;
