import React, { use, useEffect } from 'react'
import { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import scss from './Layout.module.scss'
import BaseField from 'components/BaseField';
import MuiButton from 'components/MuiButton';
import BaseSelect from 'components/BaseSelect';
import { userClassList } from 'utils/data';
import BaseButton from 'components/BaseButton';
import paths from 'utils/apiPath';
import BaseRadioGroup from 'components/BaseRadioGroup';
import axios, { api, axiosCatch, axiosState } from 'utils/axios';
import jsons, { p07 } from 'utils/jsons';
import cookie from 'utils/cookie';
import { snackInfo } from 'components/SnackBar';


var validate = require("validate.js");
//---------------------------------------------------------------------------
export default function SignUp({ setInfo }) {
  
  // const [userMonastery, setUserMonastery] = useState('') //禪寺
  const defaultClass = '' //班別
  const [userClass, setUserClass] = useState(defaultClass) //班別
  const [userClassList, setUserClassList] = useState([])
  const [userClassValue, setUserClassValue] = useState([])
  
  let location = useLocation();
  const [state, setState] = useState(axiosState.init())
  const jsonsList = jsons.site.class_list
  const apiClassList = () => {
    const config = api.config.site.class_list()
    // console.log(config);
    axios(config).then(result => {
      // console.log(result);
      // console.log(result.data);
      result.data.result_code = result.status
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        
        let data = result.data
        let items = data[p07.common.output.items] //array
        // console.log(items);
        const classNames = items.map(item => `${item[jsonsList.items.class_name]} ${item[jsonsList.items.class_time]}`); // "正淨班 一日班"
        setUserClassList(classNames)
        const classValues = items.map(item => item[jsonsList.items.class_id])
        setUserClassValue(classValues)
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  //------------------------------------------------------------------------------
  const [userName, setUserName] = useState('')
  const [userDharmaName, setUserDharmaName] = useState('') //法名
  const [userPassword, setUserPassword] = useState('')
  const [userIsStudent, setUserIsStudent] = useState(true) //學員
  const [userReferrerName, setUserReferrerName] = useState('') //介紹人
  const [referrerDisabled, setReferrerDisabled] = useState(true)
  const [signupDisabled, setSignupDisabled] = useState(true)
  
  async function onValueChangeClass(value) {
    // let value = e.target.value
    setUserClass(value)
    // console.log('Class', value);
  }
  async function onChangeName(e) {
    let value = e.target.value
    setUserName(value)
    // console.log('Name', value);
  }
  async function onChangeDharmaName(e) {
    let value = e.target.value
    setUserDharmaName(value)
    // console.log('DharmaName', value);
  }
  async function onChangePassword(e) {
    let value = e.target.value
    setUserPassword(value)
    // console.log('Password', value);
  }
  async function onChangeIsStudent(v, e) {
    setUserIsStudent(v)
    let value = Boolean(v === 'true')
    setReferrerDisabled(value)
    // console.log('IsStudent', v);
    // console.log('ReferrerDisabled', value);
  }
  async function onChangeReferrerName(e) {
    let value = e.target.value
    setUserReferrerName(value)
    // console.log('ReferrerName', value);
  }
  
  const [isOKName, setIsOKName] = useState(false)
  const [isOKPassword, setIsOKPassword] = useState(false)
  const [isOKReferrer, setIsOKReferrer] = useState(false)
  function checkInput(userClass, isOKName, isOKPassword, referrerDisabled, isOKReferrer) {
    // console.log('userClass', userClass);
    // console.log('isOKName', isOKName);
    // console.log('isOKPassword', isOKPassword);
    // console.log('referrerDisabled', referrerDisabled, 'isOKReferrer', isOKReferrer);
    let isOK = (userClass && isOKName && isOKPassword)
    if(!referrerDisabled)
      isOK = isOK && isOKReferrer
    setSignupDisabled(!isOK)
  }
  
  useEffect(() => {
    checkInput(userClass, isOKName, isOKPassword, referrerDisabled, isOKReferrer);
  }, [userClass, isOKName, isOKPassword, referrerDisabled, isOKReferrer]);
  
  //------------------------------------------------------------------------------
  const navigate = useNavigate()
  function onClickLogin() {
    navigate(paths.auth.login)
  }
  
  //------------------------------------------------------------------------------
  function onClickSignUp() {
    apiSignUp()
  }
  
  const [stateSignUp, setStateSignUp] = useState(axiosState.init())
  const apiSignUp = () => {
    setStateSignUp(axiosState.loadingState())
    const input = jsons.auth.signup.input(
      userName,
      userDharmaName,
      userClass,
      userPassword, 
      userIsStudent, 
      userReferrerName, )
    const config = api.config.auth.signup(input)
    // console.log(input);
    // console.log(config);

    axios(config).then(result => {
      // setIsLoggingIn(false);
      result.data.result_code = result.status
      if (result.data.result_code !== 200) {
        setStateSignUp(axiosState.error(false, stateSignUp.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateSignUp(axiosState.resultCode200())
        // console.log(result.data);
        
        let data = result.data
        cookie.setCookieUser(data)
        setInfo(snackInfo.openSuccess("註冊成功"))
        navigate(paths.site.home)
      }
    }).catch(err => {
      console.error("error:", err)
      setStateSignUp(axiosState.error(axiosCatch.isTimeout(err), stateSignUp.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
    })
  }
  
  //------------------------------------------------------------------------------
  useEffect(() => {
    apiClassList()
  }, [])
  
  //------------------------------------------------------------------------------
  return (
    <div className={scss.layout}>
      <h1 className={scss.sub_title}>
        註冊
      </h1>
      
      <div className={scss.box}>
        <BaseSelect defaultValue={defaultClass} itemText={userClassList} itemValue={userClassValue}
          placeholder='班級*' value={userClass} onValueChange={onValueChangeClass} />
        <BaseField placeholder='姓名*' required={true} error='請輸入姓名' maxLength={50}
          value={userName} onChange={onChangeName} setIsOK={setIsOKName} />
        <BaseField placeholder='法名' maxLength={2} minLength={2}
          value={userDharmaName} onChange={onChangeDharmaName} />
        <BaseField placeholder='生日*（月月日日 例： 0311）' required={true} error='請輸入月月日日 例： 0311' maxLength={4} 
          minLength={4} errorTooShort='請輸入4位數 <月月日日>' 
          pattern="[0-9]{4}" errorPattern='請輸入數字 0~9' 
          value={userPassword} onChange={onChangePassword} setIsOK={setIsOKPassword} />
        <BaseRadioGroup caption='學員*' required={true} itemText={['是', '否']} itemValue={['true', 'false']}
          value={String(userIsStudent)} onValueChange={(v, e) => onChangeIsStudent(v, e)}
        />
        <BaseField placeholder='介紹人' required={!referrerDisabled} error='請輸入介紹人姓名' maxLength={50} disabled={referrerDisabled}
          value={userReferrerName} onChange={onChangeReferrerName} setIsOK={setIsOKReferrer} />
      </div>
      
      <div className={scss.layout_buttom}>
        <BaseButton disabled={signupDisabled} onClick={onClickSignUp} color='green' isLoading={stateSignUp.isLoading}>
          完成</BaseButton>
        <BaseButton onClick={onClickLogin}>
          返回</BaseButton>
      </div>
    </div>);
};
