import Cookies from 'universal-cookie'
import paths from './apiPath';
import { getFullPath, isObject } from './general';
import jsons from './jsons';

// import paths from './apiPath'
// import { getFullPath } from './general';

//---------------------------------------------------------------------------
const keys = {
  auth: { //user
    token: 'p07_token',
    // id: 'p07_id',
    class: 'p07_class',
    class_time: 'p07_class_time',
    name: 'p07_name',
    dharma_name: "p07_dharma_name",
  },
};

//---------------------------------------------------------------------------
const cookies = new Cookies();

/**
 * set cookie's key and value
 * @param {string} key
 * @param {*} value
 */
const setCookie = (key, value) => {
  cookies.set(key, value, {
    path: "/",
    secure: true,
    sameSite: true,
    // sameSite: "strict",
    expires: 0,
  })
  // console.log(cookies.get(key));
};

const setCookieOneYearExpired = (key, value) => {
  // Make cookie expired after 1 year
  let today = new Date();
  today.setFullYear(today.getFullYear() + 1);
  let oneYear = today;

  cookies.set(key, value, {
    path: "/",
    secure: true,
    sameSite: true,
    // sameSite: "strict",
    expires: oneYear,
  });
  // console.log(cookies.get(key));
};

/**
 * get cookie's value
 * @param {string} key
 */
const getCookie = (key) => {
  if (cookies.get(key) === undefined) {
    return "";
  }
  return cookies.get(key);
};

/**
 * remove cookie
 * @param {string} key
 */
const removeCookie = (key) => {
  cookies.remove(key);
};

//---------------------------------------------------------------------------
const removeCookieUser = () => {
  cookies.remove(keys.auth.token);
  // cookies.remove(keys.auth.id);
  cookies.remove(keys.auth.class);
  cookies.remove(keys.auth.class_time);
  cookies.remove(keys.auth.name);
  cookies.remove(keys.auth.dharma_name);
};

const setCookieUser = (data) => {
  if(!data || !isObject(data)) {
    console.error('setCookieUser error', data);
    return ;
  }
    
  cookie.setCookie(keys.auth.token, data[jsons.auth.response.token])
  // cookie.setCookie(keys.auth.id, data.id)
  cookie.setCookie(keys.auth.class, data[jsons.auth.response.class_name])
  cookie.setCookie(keys.auth.class_time, data[jsons.auth.response.class_time])
  cookie.setCookie(keys.auth.name, data[jsons.auth.response.name])
  cookie.setCookie(keys.auth.dharma_name, data[jsons.auth.response.dharma_name])
}

function getUserClass() {
  return getCookie(keys.auth.class)
}
function getUserClassTime() {
  return getCookie(keys.auth.class_time)
}
function getUserClassHead() {
  let userClass = getUserClass() //班別 正思班
  let userClassTime = getUserClassTime() //班時 四晚班
  let title = String(userClassTime).removeAll('班')+'-'+userClass
  return title
}
function getUserName() {
  return getCookie(keys.auth.name)
}
function getUserDharmaName() {
  return getCookie(keys.auth.dharma_name)
}

const removeCookieAndJump = (navigate, location) => {
  removeCookieUser()
  // console.log('location', location)
  if( location === undefined )
    setTimeout( () => {navigate(paths.auth.login)}, 1500 );
  else {
    let fullPath = getFullPath(location)
    setTimeout( () => {navigate(`${paths.auth.login}?redirect=${encodeURIComponent(fullPath)}`)}, 1500 );
  }
}

//---------------------------------------------------------------------------
const cookie = {
  setCookie,
  setCookieOneYearExpired,
  getCookie,
  removeCookie,
  removeCookieAndJump,
  
  removeCookieUser,
  setCookieUser,
  getUserClass,
  getUserClassTime,
  getUserClassHead,
  getUserName,
  getUserDharmaName,
  
  keys,
};
export default cookie;
