import React, { useState, useEffect } from "react";

import scss from './Timer.module.scss'

//------------------------------------------------------------------------------
// https://codepen.io/kamtr0n/pen/oNYrrGO
const Counter = ({ displayValue, label }) => (
  <div className={scss.CounterStyled}>
    <h2>{label}</h2>
    {displayValue}
  </div>
);

// https://stackoverflow.com/questions/40885923/countdown-timer-in-react
//------------------------------------------------------------------------------
const Timer = ({ delayResend = 1800, isStart = false, onExpire }) => {
  const [delay, setDelay] = useState(+delayResend);
  const minutes = Math.floor(delay / 60);
  const seconds = Math.floor(delay % 60);
  
  useEffect(() => {
    if(isStart) {
      const timer = setInterval(() => {
        setDelay(delay - 1);
      }, 1000);

      if (delay === 0) {
        // console.log('Time up');
        clearInterval(timer);
        onExpire && onExpire()
      }

      return () => {
        clearInterval(timer);
      };
    }
  });

  return (
    <div className={scss.WrapperStyled}>
      <Counter displayValue={minutes} label={"分"} />
      <Counter displayValue={seconds} label={"秒"} />
    </div>
  );
};

export default Timer;