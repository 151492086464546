import React, { useEffect, useState } from 'react'

import AllLink from 'components/AllLink';
import Header from 'components/Header';
import BasicTable from 'components/BasicTable';

import scssLayout from './Layout.module.scss'
import cookie from 'utils/cookie';
import { userClassList } from 'utils/data';
import axios, { api, axiosCatch, axiosState } from 'utils/axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { snackInfo } from 'components/SnackBar';
import { p07 } from 'utils/jsons';

//---------------------------------------------------------------------------

function changeEventName(items) {
  items.map((row) => {
  switch (row.event_id) {
    case 1:
      row.event_id = "金剛經 (部)"
      break;
    case 2:
      row.event_id = "誦讀法語 (則)"
      break;
    case 3:
      row.event_id = "研讀開示 (篇)"
      break;
    case 4:
      row.event_id = "靜坐 (支香)"
      break;
    case 5:
      row.event_id = "發願 (次)"
      break;
    default:
  }})
}

//---------------------------------------------------------------------------
export default function Home({ setInfo }) {
  
  //------------------------------------------------------------------------------
  const userName = cookie.getUserName()
  const userClass = cookie.getUserClassHead()
  
  //------------------------------------------------------------------------------
  const navigate = useNavigate()
  let location = useLocation()
  const [allNumbers, setAllNumbers] = useState([])
  const [stateAll, setStateAll] = useState(axiosState.init())
  const apiAllNumbers = () => {
    const config = api.config.p07.all_numbers()
    // console.log(config);
    axios(config).then(result => {
      result.data.result_code = result.status
      if (result.data.result_code !== 200) {
        setStateAll(axiosState.error(false, stateAll.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateAll(axiosState.resultCode200())
        // console.log(result.data);
        
        let data = result.data
        let items = data[p07.common.output.items]
        changeEventName(items)
        setAllNumbers(items)
        // console.log("items'", items);
      }
    }).catch(err => {
      setStateAll(axiosState.error(axiosCatch.isTimeout(err), stateAll.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  
  const [userNumbers, setUserNumbers] = useState([])
  const [stateUser, setStateUser] = useState(axiosState.init())
  const apiUserNumbers = () => {
    const config = api.config.p07.account_numbers()
    // console.log(config);
    axios(config).then(result => {
      result.data.result_code = result.status
      if (result.data.result_code !== 200) {
        setStateUser(axiosState.error(false, stateAll.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateUser(axiosState.resultCode200())
        // console.log(result.data);
        
        let data = result.data
        let items = data[p07.common.output.items]
        changeEventName(items)
        setUserNumbers(items)
        // console.log('items', items);
      }
    }).catch(err => {
      setStateAll(axiosState.error(axiosCatch.isTimeout(err), stateAll.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  useState(() => {
    apiAllNumbers()
    apiUserNumbers()
  }, [])
  
  //------------------------------------------------------------------------------
  return (
    <div>
      <Header isHomePage={true} setInfo={setInfo}>{userName}-{userClass}</Header>
      {!stateAll.isLoading && !stateUser.isLoading &&
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "5%",
      }}>
        <BasicTable isStatic={true} category={"精舍"} rowData={allNumbers} />
        <BasicTable isStatic={true} category={"個人"} rowData={userNumbers} />
      </div>}
    </div>
    );
};
