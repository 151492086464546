
export const userClassList = [
'正淨班 一早',
'正智班 一晚',
'正業班 二早',
'正語班 二晚',
'國青班 三晚',
'正定班 四早',
'正思班 四晚',
'正見班 五早',
'正進班 五晚',
'小太陽',
'青年班',
]
export const counter = [
  1,
  2,
  3,
  4,
  5
]