import * as React from 'react';
import { Radio } from '@base-ui-components/react/radio';
import { RadioGroup } from '@base-ui-components/react/radio-group';

import styles from './BaseRadioGroup.module.scss';

//------------------------------------------------------------------------------
export default function BaseRadioGroup(props) {
  const {defaultValue, caption, itemText, itemValue, ...other} = props
  let values = itemValue ? itemValue : itemText
  let value0 = Array.isArray(values) && values.length > 0 ? values[0] : '';
  let _defaultValue = defaultValue ? defaultValue : value0
  // console.log('values', values);
  // console.log('_defaultValue', _defaultValue);
  
  //------------------------------------------------------------------------------
  return (
    <RadioGroup
      defaultValue={_defaultValue}
      className={styles.RadioGroup}
      {...other}
    >
      <div className={styles.Caption}>
        {caption}
      </div>
      
      {itemText.map((item, index) => (
        <label className={styles.Item} key={index}>
          <Radio.Root value={values[index]} className={styles.Radio} >
            <Radio.Indicator className={styles.Indicator} />
          </Radio.Root>
          {item}
        </label>
      ))}
    </RadioGroup>
  );
}
