import React, { useEffect } from 'react'
import { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import Header from 'components/Header';
import BasicTable from 'components/BasicTable';
import BaseButton from 'components/BaseButton';

import scssLayout from './Layout.module.scss'
import '../assets/css/common.scss'
import cookie from 'utils/cookie';

//---------------------------------------------------------------------------
const fakeData = {
  "item(s)": [
    {
      "event_id": 1,
      "today_count": 32,
    },
    {
      "event_id": 2,
      "total_count": 0
    }  ],
}
fakeData['item(s)'].map((row) => {
  switch (row.event_id) {
    case 1:
      row.event_id = "個人已完成"
      break;
    case 2:
      row.event_id = "今天已完成"
      break;
    default:
  }
});


export default function Sutra({ setInfo }) { //Sutra=sut. 頌經
  const userName = cookie.getUserName()
  const userClass = cookie.getUserClassHead()
  
  //------------------------------------------------------------------------------
  let staticData = fakeData['item(s)'];
    const defaultCount = 1 
    const [count, setCount] = useState(defaultCount)
  const handleChange = (event) => {
    setCount(event.target.value);
  };
  
  //------------------------------------------------------------------------------
  return (
    <div className={scssLayout.layout}>
      <Header target={"基礎：365部，進階：1080部以上"}>恭誦《金剛經》</Header>
      <div className={scssLayout.layout_content}>
        <span className="heading1">{userName}-{userClass}</span>
        <BasicTable rowData={staticData} count={count} handleChange={handleChange} />
        <BaseButton color={'green'}>新增</BaseButton>
      </div>
    </div>);
};
