// define router path
//---------------------------------------------------------------------------
const params = {
  id: "id",
  class: "cls",
};

function addParamStart(args) {
  if (args.length > 0) return "?";
  else return "";
}
function addParam(variable, value, separator = "") {
  if (value !== undefined) return `${separator}${variable}=${value}`;
  return "";
}
//---------------------------------------------------------------------------
// Authentication
const auth = {
  login: "/login",
  signup: "/signup",
};
//---------------------------------------------------------------------------
const site = {
  home: "/",
  
  meditation: '/med',
  sutra: '/sut',
  talk: '/talk',
  verse: '/verse',
  vows: '/vows'
};

//---------------------------------------------------------------------------
const paths = {
  // root: '/',
  auth,
  site,

  params,
};
export default paths;
