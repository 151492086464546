// define BE endpoint url
//---------------------------------------------------------------------------
// Authentication=account
const auth = {
  signup: "/v1/account/register", //1 register acc001
  login: "/v1/account/login", //3 login acc002
  logout: "/v1/account/logout", //4 logout acc003
};
//---------------------------------------------------------------------------
// public) Web site 
const site = {
  class_list: "/v1/vihara/class/list", //20 clss_list vh004
};

// User count
const p07 = {
  all_numbers: '/v1/vihara/statistics/describe', //9 vihara_statistics vh002
  // event_list: '/v1/vihara/event/list', //10 event_list vh003
  
  account_numbers: '/v1/account/statistics/describe', //8 account_statistics acc007
}


//---------------------------------------------------------------------------
const endpoint = {
  auth, //user
  site,
  p07, 
};
export default endpoint;
