import _axios from "axios";
import { gitInfo } from "utils/version";
import endpoint from "./apiEndpoint";
import { restDevApiUrl, restRelApiUrl, tokens } from "./apiConfig";
import jsons, { headers } from "./jsons";

//---------------------------------------------------------------------------
const isMock = false;

// console.log('gitInfo.branch', gitInfo.branch);
let restApiUrl =
  gitInfo.branch === "master" || gitInfo.branch === "hotfix"
    ? restRelApiUrl
    : restDevApiUrl;

// const restTimeout = 5000
const restTimeout = 0; //no limit

const axios = (config) => {
  // console.log('restApiUrl', restApiUrl);
  const instance = _axios.create({
    baseURL: config || restApiUrl,
    timeout: restTimeout,
    // CORS BE suggestion
    /* crossOrigin: true,
    cors: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type",
      "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
      "Access-Control-Allow-Credentials": "false",
      "Access-Control-Expose-Headers": "X-My-Custom-Header",
    }, */
  });
  // console.log(instance)
  return instance;
};

export class axiosState {
  static set(isLoading, isTimeout, isResultCode200, numResultError) {
    return { isLoading, isTimeout, isResultCode200, numResultError };
  }
  static init() {
    return {
      isLoading: true,
      isTimeout: false,
      isResultCode200: false,
      numResultError: 0,
    };
  }
  static error(isTimeout, numResultError) {
    return {
      isLoading: false,
      isTimeout: isTimeout,
      isResultCode200: false,
      numResultError: numResultError,
    };
  }
  static resultCode200() {
    return {
      isLoading: false,
      isTimeout: false,
      isResultCode200: true,
      numResultError: 0,
    };
  }
  static keepRest(state) {
    if (
      state.isTimeout === false &&
      state.isResultCode200 === false &&
      state.numResultError < 5
    )
      return true;
    else return false;
  }
  static loadingState(state) {
    return {
      isLoading: true,
      isTimeout: false,
      numResultError: 0,
    };
  }
}

export class axiosCatch {
  static getMsg(err) {
    // console.log(err.status);
    let msg = err.message;
    if (err.response !== undefined) {
      let code = this.getResultCode(err)
      // console.log(err.response.status);
      // console.log(err.status);
      switch (err.response.status) {
        case 400:
        case 401:
        case 500:
          if(code !== 0 && err.response.data && err.response.data.reason)
            msg = err.response.data.reason
          else
            msg = err.response.data ? err.response.data.message : err.statusText;
          break;
        default:
          msg = err.message;
      }
    }
    if(err.reason)
      msg = `${msg}! ${err.reason}`
    // console.log('msg', msg);
    return msg;
  }
  static getResultCode(err) {
    let code = 0;
    if (err.response !== undefined && err.response.data !== undefined) {
      code = err.response.data[jsons.common.response.code];
      // console.log(err.response);
      // console.log(code);
    }
    return code;
  }
  static isTimeout(err) {
    if (err.code === "ECONNABORTED")
      //msg === 'timeout of 5000ms exceeded'
      return true;
    return false;
  }
  static needLogin(err) {
    let code = 0;
    if (err.response !== undefined && err.response.data !== undefined) {
      code = err.response.data[jsons.common.response.code];
      console.log(code, err.response.data);
      // 910=Unauthorized Authentication
      // 911=Token Expired
      // 913=This account has been locked 
      if (code === 911 || code === 913)
        return true;
    }
    return false;
  }
}

class axiosConfig {
  static post = (input, url, timeout = restTimeout) => ({
    method: "post",
    url: url,
    headers: headers.json(),
    data: JSON.stringify(input && Object.keys(input).length ? input : {}),
    timeout: timeout,
  });
  static postDefault = (input, url) => ({
    method: "post",
    url: url,
    headers: headers.jsonDefault,
    data: JSON.stringify(input && Object.keys(input).length ? input : {}),
  });
  static postFile = (data, url) => ({
    method: "post",
    url: url,
    headers: {
      ...headers.json(),
      "Content-Type": "multipart/form-data",
    },
    data: data,
    maxBodyLength: Infinity,
  })
  //---------------------------------------------------------------------------
  static get = (url, timeout = restTimeout) => ({
    method: "get",
    url: url,
    headers: headers.json(),
    timeout: timeout,
  });
  static getDefault = (url) => ({
    method: "get",
    url: url,
    headers: headers.jsonDefault, //"System(role=1) [default token] cat_id=0"
  });
}

// -----------------------------------------------------------------------------
// auth config
class auth {
  //1 register acc001
  static signup = (input) =>
    axiosConfig.postDefault(input, endpoint.auth.signup); //1 register acc001
  //3 login acc002
  static login = (input) => axiosConfig.postDefault(input, endpoint.auth.login); //3 login acc002
  //4 logout acc003
  static logout = (token) => ({
    method: "get",
    url: endpoint.auth.logout, //4 logout acc003
    headers: headers.token(token),
  });
}

//---------------------------------------------------------------------------
// site config
class site {
  //20 clss_list vh004
  static class_list = () => axiosConfig.getDefault(endpoint.site.class_list);
}

//------------------------------------------------------------------------------
// p07 config
class p07 {
  /* 
  static xxx = () =>
    axiosConfig.get(endpoint.p07.xxx);
  static yyy = (input) =>
    axiosConfig.post(input, endpoint.p07.yyy);
   */
  static all_numbers = (input) =>
    axiosConfig.post(input, endpoint.p07.all_numbers);
    
  static account_numbers = (input) =>
    axiosConfig.post(input, endpoint.p07.account_numbers);
}

//---------------------------------------------------------------------------
export const apiConfig = {
  auth, //user
  site,
  p07,
};

export const api = {
  config: apiConfig,
  tokens: tokens,
}

export { axios };
export default axios();
