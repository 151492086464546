export const isEmptyObjOrArray = (obj) => {
  if (Array.isArray(obj)) {
    return obj.length === 0;
  }
  if (typeof obj === "object" && obj !== null) {
    return Object.keys(obj).length === 0;
  }

  if (obj === null) {
    return true;
  }

  if (obj === undefined) {
    return true;
  }
  return false;
};

export function isObject(obj) {
  return (obj !== null && typeof obj === "object" && !Array.isArray(obj))
}

export function getFullPath(location) {
  if( isObject(location) ) {
    // console.log('location', location)
    if( location.pathname !== undefined )
      return location.pathname + location.search
  }
  return ''
}