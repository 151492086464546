import React, { useEffect } from 'react'
import { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import scssLayout from './Layout.module.scss'
import Header from 'components/Header';
import cookie from 'utils/cookie';

//---------------------------------------------------------------------------
export default function Talk({ setInfo }) { //Dharma talk=開示
  const userName = cookie.getUserName()
  const userClass = cookie.getUserClassHead()
  //------------------------------------------------------------------------------
  
  
  //------------------------------------------------------------------------------
  return (
    <div className={scssLayout.layout}>
      <Header target={"基礎：12篇，進階：24篇"}>研讀開示</Header>
      <div className={scssLayout.layout_content}>
        <span className="heading1">{userName}-{userClass}</span>
      </div>
    </div>);
};
