import React, { use, useEffect } from 'react'
import { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import scss from './Layout.module.scss'
import BaseField from 'components/BaseField';
import MuiButton from 'components/MuiButton';
import BaseSelect from 'components/BaseSelect';
import BaseButton from 'components/BaseButton';
import paths from 'utils/apiPath';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/axios';
import jsons, { p07 } from 'utils/jsons';
import cookie from 'utils/cookie';
import { snackInfo } from 'components/SnackBar';

//---------------------------------------------------------------------------
export default function Login({ setInfo }) {
  
  // const [userMonastery, setUserMonastery] = useState('') //禪寺
  const defaultClass = '' //班別
  const [userClass, setUserClass] = useState(defaultClass) //班別
  const [userClassList, setUserClassList] = useState([])
  const [userClassValue, setUserClassValue] = useState([])
  
  let location = useLocation();
  const [state, setState] = useState(axiosState.init())
  const jsonsList = jsons.site.class_list
  const apiClassList = () => {
    const config = apiConfig.site.class_list()
    // console.log(config);
    axios(config).then(result => {
      // console.log(result);
      // console.log(result.data);
      result.data.result_code = result.status
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        
        let data = result.data
        let items = data[p07.common.output.items] //array
        // console.log(items);
        const classNames = items.map(item => `${item[jsonsList.items.class_name]} ${item[jsonsList.items.class_time]}`); // "正淨班 一日班"
        setUserClassList(classNames)
        const classValues = items.map(item => item[jsonsList.items.class_id])
        setUserClassValue(classValues)
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  //------------------------------------------------------------------------------
  const [userName, setUserName] = useState('')
  const [userPassword, setUserPassword] = useState('')
  const [loginDisabled, setLoginDisabled] = useState(true)
  
  async function onValueChangeClass(value) {
    // let value = e.target.value
    setUserClass(value)
    // console.log('Class', value);
  }
  async function onChangeName(e) {
    let value = e.target.value
    setUserName(value)
    // console.log('Name', value);
  }
  async function onChangePassword(e) {
    let value = e.target.value
    setUserPassword(value)
    // console.log('Password', value);
    // console.log('validity', e.target.validity);
    // console.log('target', e.target);
  }
  const onKeyPress = (event) => {
    if (event.key === 'Enter' && loginDisabled) {
      apiLogin()
    }
  }
  
  const [isOKName, setIsOKName] = useState(false)
  const [isOKPassword, setIsOKPassword] = useState(false)
  function checkInput(userClass, isOKName, isOKPassword) {
    // console.log('userClass', userClass);
    // console.log('isOKName', isOKName);
    // console.log('isOKPassword', isOKPassword);
    let isOK = (userClass && isOKName && isOKPassword)
    setLoginDisabled(!isOK)
    // console.log('isOK', isOK);
  }
  
  useEffect(() => {
    checkInput(userClass, isOKName, isOKPassword);
  }, [userClass, isOKName, isOKPassword]);
  
  //------------------------------------------------------------------------------
  function onClickLogin() {
    apiLogin()
  }
  
  const navigate = useNavigate()
  let queryParams = new URLSearchParams(location.search);
  let redirectPath = queryParams.get('redirect');
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [stateLogin, setStateLogin] = useState(axiosState.init())
  const apiLogin = () => {
    setIsLoggingIn(true);

    const input = jsons.auth.login.input(
      userName,
      userClass,
      userPassword)
    const config = apiConfig.auth.login(input)
    console.log(input);
    // console.log(config);

    axios(config).then(result => {
      setIsLoggingIn(false);
      // console.log("Login result:", result)
      result.data.result_code = result.status
      if (result.data.result_code !== 200) {
        setStateLogin(axiosState.error(false, stateLogin.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateLogin(axiosState.resultCode200())
        // console.log(result.data);
        
        let data = result.data
        cookie.setCookieUser(data)
        setInfo(snackInfo.openSuccess("登入成功"))
        
        if (redirectPath !== "" && redirectPath !== undefined) {
          navigate(redirectPath)
        }else{
          navigate(paths.site.home)
        }
      }
    }).catch(err => {
      console.error("Login error:", err)
      setIsLoggingIn(false);
      setStateLogin(axiosState.error(axiosCatch.isTimeout(err), stateLogin.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))      
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  //------------------------------------------------------------------------------
  function onClickSignUp() {
    navigate(paths.auth.signup)
  }
  
  //------------------------------------------------------------------------------
  useEffect(() => {
    apiClassList()
  }, [])
  
  //------------------------------------------------------------------------------
  return (
    <div className={scss.layout}>
      <h1 className={scss.title_login}>
        普民精舍
      </h1>
      
      <div className={scss.box}>
        <BaseSelect defaultValue={defaultClass} itemText={userClassList} itemValue={userClassValue}
          placeholder='班級*' value={userClass} onValueChange={onValueChangeClass} />
        <BaseField placeholder='姓名*' required={true} error='請輸入姓名' maxLength={50}
          value={userName} onChange={onChangeName} setIsOK={setIsOKName} onKeyPress={onKeyPress} />
        <BaseField placeholder='生日*' required={true} error='請輸入生日 MMDD' maxLength={4} 
          minLength={4} errorTooShort='請輸入4位數 MMDD' 
          pattern="[0-9]{4}" errorPattern='請輸入數字 0~9' 
          value={userPassword} onChange={onChangePassword} setIsOK={setIsOKPassword} onKeyPress={onKeyPress} />
      </div>
      
      <div className={scss.layout_buttom}>
        <BaseButton disabled={loginDisabled} onClick={onClickLogin} loading={String(isLoggingIn)} color='green'>
          登入</BaseButton>
        <BaseButton onClick={onClickSignUp}>
          註冊</BaseButton>
      </div>
    </div>);
};
